<template>
	<PageLayout :title="$t('deposit.title')">
		<div v-if="!loading" class="cashier-box cashier-box--deposit">
			<payment-logo custom-logo="cards.svg" />
			<div class="small-width overflow-visible">
				<payment-wallet />
			</div>

			<div class="line-separator-wrapper">
				<div class="line-separator" />
			</div>

			<amount-buttons
				:buttons-value="currentMethod.predefinedDepositAmounts"
				:form-amount-value="form.amount || currentMethod.predefinedDepositAmounts[0]"
				:currency="currency"
				:form-errors="formErrors['amount']"
				:current-method="currentMethod"
				:overridden-min-amount="minAmount"
				@set-button-value="setButtonValue"
				@input-amount-value-validation="setFormValidation"
			/>

			<button type="button" class="button primary-button deposit center" :disabled="!form.validated" @click="pay()">
				{{ $t('deposit.top-up') }}
			</button>

			<div v-if="loading" class="loader-wrapper">
				<loader />
			</div>
		</div>
	</PageLayout>
</template>
<script>
import { mapGetters } from 'vuex';
import PageLayout from '@/components/page-layout/PageLayout';
import apiClient from '../../../../api';

const loader = () => import('../../../../shared/loader/Loader.vue');
const paymentLogo = () => import('../../../../shared/payment-logo/PaymentLogo');
const amountButtons = () => import('../../../../shared/amount-buttons/AmountButtons');
const paymentWallet = () => import('../../../../shared/payment-wallet/PaymentWallet');

export default {
	name: 'DepositPropay',
	components: {
		PageLayout,
		loader,
		paymentLogo,
		amountButtons,
		paymentWallet,
	},
	data() {
		return {
			form: {
				amount: null,
				promocode: null,
				validated: true,
			},
			currency: 'TRY',
			minAmount: null,
			formErrors: {
				amount: null,
			},
			loading: true,
			paymentDone: false,
			walletId: null,
		};
	},
	computed: {
		...mapGetters(['isEmbedded']),
		currentMethod() {
			return this.$store.getters.getCurrentMethod('propay');
		},
	},

	async mounted() {
		this.loading = false;
		await this.getUserWallet();
	},
	methods: {
		async getUserWallet() {
			try {
				const { id } = await apiClient.fetchFullWallet(this.$route.params.walletId);
				this.walletId = id;
			} catch (e) {
				this.error = 'wallets.failed-to-load-wallet';
			} finally {
				this.loading = false;
			}
		},
		setButtonValue(event) {
			this.form.amount = event;
		},
		setPromoValue(event) {
			this.form.promocode = event;
		},
		setFormValidation(event) {
			this.form.validated = event;
		},
		async createTransaction(amount, currency) {
			const response = await apiClient.propayCreateTransaction(this.walletId, amount, currency);
			return response.data;
		},
		async pay() {
			this.loading = true;

			try {
				const { link } = await this.createTransaction(this.form.amount, this.currency);

				if (this.isEmbedded) {
					window.open(link, '_blank');
					const walletId = this.$route.params.walletId;
					this.$router.push({
						path: `/payments/deposit/${walletId}/confirmation`,
						query: { status: 'external' },
					});
				} else {
					window.location.replace(link);
				}
			} catch (e) {
				const walletId = this.$route.params.walletId;
				this.$router.push({
					path: `/payments/deposit/${walletId}/confirmation`,
					query: { status: 'error' },
				});
				console.error('Payment failed', e);
			} finally {
				this.loading = false;
			}
		},
	},
};
</script>
